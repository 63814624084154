import { Injectable, SystemJsNgModuleLoader, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AppHelperService } from './app-helper.service';
import { AvalonLookupModels, AvalonModels } from '../models/avalon.models';

@Injectable({
  providedIn: "root"
})
export class GenesisProviderService {
  apiUrl: string = "";
  services = {
    UriValidateLogin: "/genesis/api/genesis/ValidateLogin",
    UriTerminateUserSession: "/genesis/api/genesis/TerminateUserSession",
    UriReadAbe: "/genesis/api/genesis/Read",
    UriReadAll: "/genesis/api/genesis/ReadAll",
    UriListByOwner: "/genesis/api/genesis/ListByOwner",
    UriListByRelationship: "/genesis/api/genesis/ListByRelationship",
    UriSave: "/genesis/api/genesis/Save",
    UriSaveList: "/genesis/api/genesis/SaveList",
    UriSaveWithRelationship: "/genesis/api/genesis/SaveByRelationship",
    UriDeleteRelationship: "/genesis/api/genesis/DeleteRelationship",
    UriDelete: "/genesis/api/genesis/Delete",
    UriDeleteList: "/genesis/api/genesis/DeleteList",
    UriResetPassword: "/genesis/api/genesis/ResetPassword",
    UriChangePassword: "/genesis/api/genesis/ChangePassword",

    UriGetMD5Checksum: "/genesis/api/genesis/GetMDSChecksum",
    UriGetAllLookups: "/genesis/api/genesis/GetAllLookups",
    UriListEntityRelations: "/genesis/api/genesis/ListEntityRelations",
    UriSearchEntityType: "/genesis/api/genesis/Search_EntityType",
    UriGetCartItemPrice: "/genesis/api/genesis/GetCartItemPrice",
    UriReadOrgTree: "/genesis/api/genesis/ReadOrgTree",
    UriExecuteSubscriptionRun: "/genesis/api/genesis/ExecuteSubscriptionRun",
    UriListUserTasks: "/genesis/api/genesis/ListUserTasks",
    UriDuplicateRules_GetMatchResults:
      "/genesis/api/genesis/DuplicateDuplicateRule_GetMatchResults",
    UriGenerateReport: "/genesis/api/genesis/GenerateReport",
    UriGetSavedSearchResults: "/genesis/api/genesis/GetSavedSearchResults",
    UriMakePayment: "/genesis/api/genesis/MakePayment",
    UriGetProductPrice: "/genesis/api/genesis/GetProductPrice",
    UriGetInstallmentSchedule: "/genesis/api/genesis/GetInstallmentSchedule",
    UriGetEnrichedText: "/genesis/api/genesis/GetEnrichedText"
  };

  sysUsr: AvalonModels.SysUser;

  constructor(private http: HttpClient, @Inject('endpoint') private endpoint: string) {
    this.apiUrl = this.endpoint;
  }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  private getServiceUrl(serviceName: string) : string {
    return this.apiUrl + serviceName;
  }

  log(message : string){
    console.log(message);
  }

  callGenesis<T>(url, args) : Observable<T>  {
    return this.http.post<T>(this.getServiceUrl(url), args, this.httpOptions)
      .pipe(
        catchError(err=>{
          this.log(err);
          return err;
        }),
        map((returnObj:any)=>{
          if(returnObj.result != null){
            if(returnObj.result.status != null && returnObj.result.status != 0) {
              var errors = JSON.stringify(returnObj.result.errors);
              throw new Error(errors);
            }
          }
          this.convertDates(returnObj);
          return returnObj;
        })
      )
  }
  isoDateFormat: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z?$/;
  convertDates(obj: any){
    for(let prop in obj){
      let val = obj[prop];
      if(val == null) continue;
      if (typeof val === "string" && this.isoDateFormat.test(val)){
          obj[prop] = new Date(val);
      } else if (typeof val === "object") {
        this.convertDates(val);
      }
    }
  }

  validateLogin(usr: AvalonModels.SysUser, clearSession: boolean = false): Observable<any> {
    return this.callGenesis(this.services.UriValidateLogin, {
                usr: usr,
                clearExistingSession: clearSession
            });
  }

  loadLookups(): Observable<AvalonLookupModels.ABELookupTable[]> {
    return this.callGenesis(this.services.UriGetAllLookups, {
      usr: this.sysUsr
    }).pipe(
        map((result:any,idx) => <AvalonLookupModels.ABELookupTable[]>result.lookups)
      );
    
    // this.http.post<AccreditationModels.ABELookupTable[]>(this.getServiceUrl(this.services.UriGetAllLookups), {
    //   usr: this.sysUsr
    // }, this.httpOptions)
    // .pipe(
    //   map((result:any,idx) => <AccreditationModels.ABELookupTable[]>result.lookups)
    // );
  }

  readAbe<T extends AvalonModels.avionbusinessentity>(abeToRead:T) : Observable<T> {
    var args = {
      abe: abeToRead,
      usr: this.sysUsr
    }
    return this.callGenesis<T>(this.services.UriReadAbe, args)
      .pipe(
        map((result:any, idx)=> {
          return <T>(result.enrichedAbe);
        })
      );
  }

  saveAbe<T extends AvalonModels.avionbusinessentity>(abeToSave:T) : Observable<T> {
    var args = {
      abe: abeToSave,
      usr: this.sysUsr
    }
    return this.callGenesis<T>(this.services.UriSave, args)
      .pipe(
        map((result:any, idx)=> {
          return <T>(result.enrichedAbe);
        })
      );
  }

  deleteList<T extends AvalonModels.avionbusinessentity>(abesToDelete: T[]) : Observable<any> {
    var args = {
      abes: abesToDelete,
      usr: this.sysUsr
    };
    return this.callGenesis<T>(this.services.UriDeleteList, args)
        .pipe(
          map((result:any, idx)=> {
            return result;
          })
        )
  }

  saveAbeList<T extends AvalonModels.avionbusinessentity>(abesToSave: T[]) : Observable<T[]> {
    var args = {
      abes: abesToSave,
      usr: this.sysUsr
    };
    return this.callGenesis<T>(this.services.UriSaveList, args)
        .pipe(
          map((result:any, idx)=> {
            return <T[]>(result.enrichedAbes);
          })
        )
  }

  listByOwner<T extends AvalonModels.avionbusinessentity>(ownerAbe:AvalonModels.avionbusinessentity, returnAbe: T) : Observable<T[]> {
    var args = {
      entityTypeId: returnAbe.EntityTypeId,
      ownerEntityTypeId: ownerAbe.EntityTypeId,
      ownerEntityId: ownerAbe.EntityId,
      usr: this.sysUsr
    }
    return this.callGenesis<T[]>(this.services.UriListByOwner, args)
      .pipe(
        map((result:any, idx)=> {
          return <T[]>(result.abeList);
        })
      );
  }

  listByRelationship<T extends AvalonModels.avionbusinessentity>(ownerAbe:AvalonModels.avionbusinessentity, returnAbe: T) : Observable<T[]> {
    var args = {
      entityTypeId: returnAbe.EntityTypeId,
      relatedEntityTypeId: ownerAbe.EntityTypeId,
      relatedEntityId: ownerAbe.EntityId,
      usr: this.sysUsr
    };
    return this.callGenesis<T[]>(this.services.UriListByRelationship, args)
      .pipe(
        map((result:any, idx)=> {
          return <T[]>(result.abeList);
        })
      );
  }

  readAll<T extends AvalonModels.avionbusinessentity>(returnAbe: T) : Observable<T[]> {
    var args = {
      abe: returnAbe, 
      usr: this.sysUsr
    }
    return this.callGenesis<T[]>(this.services.UriReadAll, args)
      .pipe(
        map((result:any, idx)=> {
          return <T[]>(result.abeList);
        })
      );
  }

  deleteAbe<T extends AvalonModels.avionbusinessentity>(abeToDelete: T) : Observable<any> {
    var args = {
      abe: abeToDelete,
      usr: this.sysUsr
    };
    return this.callGenesis(this.services.UriDelete, args);
  }
}
