import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, SystemJsNgModuleLoader } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { AvalonLookupModels, AvalonModels } from '../models/avalon.models';
import { SalModels } from '../models/sal.models';
import { GenesisProviderService } from './genesis-service-provider.service';


@Injectable({
    providedIn: 'root'
  })
  export class AppHelperService {
    
    genesisService: GenesisProviderService;
    lookups: AvalonLookupModels.ABELookupTable[];
    loggedInIndividual: AvalonModels.individual;
    loggedInSites: AvalonModels.Site[];
    loggedInGroups: AvalonModels.SysGroup[];
    survey: SalModels.SalSurvey;
    question: SalModels.SalSurveyQuestion;
    surveyRun: SalModels.SalSurveyRun;
    responseHeader: SalModels.SalResponseHeader;

    loginStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    headerTitleChanged: EventEmitter<string> = new EventEmitter<string>();
    
    constructor(private genesis: GenesisProviderService, private httpClient: HttpClient) { 
      this.genesisService = genesis;
    }

    loadLookups() : Observable<AvalonLookupModels.ABELookupTable[]> {
        if(this.lookups == null || this.lookups.length == 0){
          return this.genesisService.loadLookups().pipe(map(x=>this.lookups = x));
        } else {
          return of(this.lookups);
        }
    }

    getEntityString(obj: AvalonModels.avionbusinessentity) : string {
      return `${obj.EntityTypeId}^${obj.EntityId}^${obj.Description}^${obj.DisplayId}`;
    }

    getEntityObject<T extends AvalonModels.avionbusinessentity>(entityString: string) : T {
      let objArray = entityString.split("^");
      let returnAbe = new AvalonModels.avionbusinessentity(objArray[0]);
      returnAbe.EntityId = objArray[1];
      returnAbe.Description = objArray[2];
      returnAbe.DisplayId = Number(objArray[3]);
      return <T>returnAbe;
    }

    isLookupMatch(lookupId: string, lookupName: string) : boolean {
      if(lookupId == null) return false;
      let match = this.lookups.find(t=>{
        let v = t.Values.find(val => val.Id.toLowerCase() == lookupId.toLowerCase());
        if(v != null){ return v.LookupName.toLowerCase() == lookupName.toLowerCase();}
        return false;
      });
      if(match != null) {
        return true;
      } else {
        return false;
      }
    }

    getLookupObject(lookupId: string, lookupTable: string) : AvalonLookupModels.ABELookupValue {
      if(this.lookups != null){
        let table = this.lookups.find(t=>t.TableName == lookupTable);
        if(table != null){
          let value = table.Values.find(v=>v.Id == lookupId);
          if(value != null) return value;
        }
      }
      return null;
    }

    getLookupName(lookupId: string, lookupTable: string) : string {
      if(this.lookups != null){
        let table = this.lookups.find(t=>t.TableName == lookupTable);
        if(table != null){
          let value = table.Values.find(v=>v.Id.toLowerCase() == lookupId.toLowerCase());
          if(value != null) return value.LookupName;
        }
      }
      return null;
    }

    getLookupId(lookupName: string, lookupTable: string) : string {
      if(this.lookups != null){
        let table = this.lookups.find(t=>t.TableName == lookupTable);
        if(table != null){
          let value = table.Values.find(v=>v.LookupName == lookupName);
          if(value != null) return value.Id;
        }
      }
      return "";
    }

    isNullOrEmpty(val?: string){
      if(val == null) return true;
      if(val == "") return true;

      return false;
    }

    log(message:string) {
        console.log(message);
    }

    getLoggedInIndividual() : Observable<AvalonModels.individual> {
      if(this.loggedInIndividual != null){
        return of(this.loggedInIndividual);
      } else {
        let ind = new AvalonModels.individual();
        ind.EntityId = this.genesisService.sysUsr.EntityId;
        
        return this.genesisService.readAbe(ind)
              .pipe(
                map(i=>{
                  this.loggedInIndividual = i;
                  return this.loggedInIndividual;
                })
            );
      }
    }

    login(username: string, password: string) : Observable<boolean> {
      let usr = new AvalonModels.SysUser();
      usr.UserName = username;
      usr.Password = password;
      return this.genesisService.validateLogin(usr, true)
                          .pipe(
                            map(retObj=>{
                              this.genesisService.sysUsr = retObj.enrichedUsr;
                              this.loggedInSites = retObj.sites;
                              this.loggedInGroups = retObj.LoggedInGroups;
                              this.loginStateChanged.emit(true);
                              return true;
                            }),
                            catchError(err=>{
                              console.log(err);
                              return of(false);
                            }));
    }

    logout() : void {
      this.genesisService.sysUsr = null;
      this.loginStateChanged.emit(false);
    }

    setQuestion(question: SalModels.SalSurveyQuestion) {
      this.question = question;
      this.setHeaderTitle(question != null ? question.QuestionTitle : "");
    }

    setHeaderTitle(title: string) {
      this.headerTitleChanged.emit(title);
    }

    getIpAddress(): Observable<string> {
      const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
      return this.httpClient.get("https://api.ipify.org", {headers: headers, responseType: 'text'})
                     .pipe(
                       map(x=>{
                         return <string>x;
                       })
                     );
    }
}