import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SalModels } from 'src/app/models/sal.models';
import { AppHelperService } from 'src/app/services/app-helper.service';

@Component({
  selector: 'app-survey-question',
  templateUrl: './survey-question.component.html',
  styleUrls: ['./survey-question.component.scss']
})
export class SurveyQuestionComponent implements OnInit {

  question: SalModels.SalSurveyQuestion;
  questionTypes = SalModels.QuestionType;
  questionType: SalModels.QuestionType;
  readOnly: boolean = false;

  constructor(private appHelper: AppHelperService, 
    private router: Router,
    private route: ActivatedRoute) { 
      
    }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.loadQuestion(params.get("id"));
    })
  }

  loadQuestion(questionId: string) {
    this.readOnly = this.appHelper.isLookupMatch(this.appHelper.surveyRun.Status, "Completed");
    let survey = this.appHelper.survey;
    this.question = survey.questions.find(q=>q.DisplayId.toString() == questionId);
    //check if this one has a response, if yes, load it up..
    if(this.appHelper.responseHeader != null) {
      let response = this.appHelper.responseHeader.responses.find(r=>r.question_id.toLowerCase() == this.question.EntityId.toLowerCase());
      if(response != null){
        switch (this.question.questionType) {
          case this.questionTypes.SingleChoice:
            if(response.response_id != null){
              this.question.selectedResponseValue = response.response_id;
              this.question.responses.forEach(r=>r.isSelected = r.EntityId == response.response_id);
            }
            break;
          case this.questionTypes.MultipleChoice:
            if(response.response_id != null){
              this.question.responses.forEach(r=>r.isSelected = response.response_id.toLowerCase().indexOf(r.EntityId.toLowerCase()) > -1);
            }
            break;
          case this.questionTypes.FreeText:
            this.question.selectedResponseValue = response.response_value;
            break;
        
          default:
            break;
        }
      }
    }
  }
}
