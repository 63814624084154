import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AvalonModels } from './models/avalon.models';
import { AppHelperService } from './services/app-helper.service';
import { routeTransitionAnimations } from './modules/router-animation';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeTransitionAnimations]
})
export class AppComponent {
  title = '';

  loggedInIndividual: AvalonModels.individual;
  isLoggedIn: boolean;
  constructor(private appHelper: AppHelperService, private router: Router){
    this.appHelper.loginStateChanged.subscribe((x:boolean)=>{
      this.isLoggedIn = x;
      if(x) {
        this.appHelper.getLoggedInIndividual()
                      .pipe(
                        map(i=>{
                          this.loggedInIndividual = i;
                        })
                      ).subscribe();
      } else {
        this.loggedInIndividual = null;
      }
    });
    this.appHelper.headerTitleChanged.subscribe((x:string)=>{
      this.title = x;
    });
  }

  logout() : void {
    this.appHelper.logout();
    this.router.navigate(["/"]);
  }
}
