import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { SurveyQuestionComponent } from './components/survey-question/survey-question.component';
import { SurveyComponent } from './components/survey/survey.component';
import { UnauthorisedComponent } from './components/unauthorised/unauthorised.component';
import { AccessControlActivate } from './services/access-control-activate';
import { LookupsResolver } from './services/lookups-resolver';


const routes: Routes = [
  { path:'', component: LoginComponent},
  { path:'unauthorised', component: UnauthorisedComponent},
  { path:'dashboard', component: DashboardComponent, resolve: {lookups: LookupsResolver}, canActivate: [AccessControlActivate]},
  {
    path: 'survey/:id', component: SurveyComponent, resolve: {lookups: LookupsResolver}, canActivate: [AccessControlActivate],
    children: [
      {path: 'survey-question/:id', component: SurveyQuestionComponent, resolve: {lookups: LookupsResolver}, canActivate: [AccessControlActivate], outlet: "detail"},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
