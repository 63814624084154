export namespace AvalonModels {
  export class avionbusinessentity {
    readonly EntityTypeId: string = "7078E4B8-2464-42C2-B633-0275D48CA2E3";
    constructor(entityTypeId: string) {
      this.EntityTypeId = entityTypeId;
      this.EntityId = "00000000-0000-0000-0000-000000000000";
    }
    AbeName: string;
    AllowFollowupRequests?: boolean;
    CloneSourceId?: string;
    CreationDate?: Date;
    Creator?: string;
    CreatorDesc?: string;
    Description?: string;
    DescriptionDefinition?: string;
    DisplayId?: number;
    DisplayName?: string;
    EntityId: string;
    Icon?: string;
    IsNavigable?: boolean;
    IsSystem?: boolean;
    LegacySystemDescription?: string;
    LegacySystemIdentifier?: string;
    ModificationDate?: Date;
    Modifier?: string;
    ModifierDesc?: string;
    OwnerDescription?: string;
    OwnerEntityId?: string;
    OwnerEntityTypeId?: string;
    OwnerEntity?: string;
    PersistenceEntityName?: string;
    PersistenceEntityTypeId?: string;
    Type?: string;
  }
  export class AbeDescriptions extends avionbusinessentity {
    constructor() {
      super("81DD68F1-4212-4560-95D8-682C8BE66BD8");
    }
    BusinessEntity: string;
    MegaDescription: string;
    TitleDescription?: string;
  }
  export class abelookuptable extends avionbusinessentity {
    constructor() {
      super("9998FED8-7992-4ADD-AF07-C2C51CB089BB");
    }
    Name: string;
    ParentId?: string;
    TableName?: string;
  }
  export class abelookupvalue extends avionbusinessentity {
    constructor() {
      super("DA6F99E3-4A5A-4BE6-9F4A-BB5530818B16");
    }
    AllowedContexts?: string;
    ExtraData?: string;
    LookupName?: string;
    ParentValueId?: string;
    Sequence?: number;
  }
  export class AccessLog extends avionbusinessentity {
    constructor() {
      super("2A4C9503-10E0-433A-9A65-9C23AD063840");
    }
    AccessedBy: string;
    AccessTime: Date;
    AccessType?: string;
    EntityAccessed: string;
  }
  export class AccreditationInfo extends avionbusinessentity {
    constructor() {
      super("D6036E32-A4D6-4322-93F2-FB10DDBAEEF1");
    }
    AgentInformed?: boolean;
    Deregistered?: boolean;
    EffectiveFromDate?: Date;
    EffectiveToDate?: Date;
    ExistingAccreditationStatus: string;
    Extended?: boolean;
    ExtendedBy?: string;
    ExtensionDate?: Date;
    NewAccreditationStatus: string;
    StatusChangeDate: Date;
  }
  export class ActionDefinition extends avionbusinessentity {
    constructor() {
      super("D2CFF8C3-9052-4C9D-AD9A-9CB658B3425D");
    }
    Details?: string;
    EntityContext?: string;
    Library: string;
    Name: string;
    Namespace: string;
    SystemActionMap?: string;
  }
  export class address extends avionbusinessentity {
    constructor() {
      super("43215A69-9A54-4CFA-A5F5-B4C0AD0A9D9A");
    }
    Address1?: string;
    Address2?: string;
    Address3?: string;
    Country?: string;
    Postcode?: string;
    Preferred: boolean;
    State?: string;
    StateOther?: string;
    Suburb: string;
    Type: string;
  }
  export class alert extends avionbusinessentity {
    constructor() {
      super("0B5A93B3-C4FB-4E97-B8D6-CB9961D22699");
    }
    AlertText: string;
    Status: string;
    SubType: string;
    Type: string;
  }
  export class AssignmentInfo extends avionbusinessentity {
    constructor() {
      super("AB210A4E-FE8F-4C37-8704-806CEFA5248D");
    }
    AccreditationHistory?: string;
    AccreditationStatus: string;
    AgentConsent?: boolean;
    AgentConsentDate?: Date;
    AgentID?: string;
    AssignmentCategory?: string;
    AssignmentState?: string;
    AwardDate: Date;
    Corporate: string;
    CorporateEmployed?: boolean;
    CriminalHistoryCheckDate?: Date;
    CriminalHistoryChecked?: boolean;
    CriminalHistoryPending?: boolean;
    DateCHCAppLodged?: Date;
    ExpiryDate: Date;
    IBU?: string;
    IDChecked?: boolean;
    LatestAccreditationId?: string;
    Marketer?: string;
    MilestoneAwardRenewal?: Date;
    MilestoneProvisionalCompletion?: Date;
    MilestoneSixDayCompletion?: Date;
    PreviouslyEmployed?: boolean;
    ProductsSold?: string;
    RegistrationLevel: string;
    StartDate: Date;
    SubAgency?: string;
  }
  export class AssociatedABE extends avionbusinessentity {
    constructor() {
      super("CCBE66D9-1976-4215-A41A-ADCD0995DA8A");
    }
    AssociatedABETypeId: string;
    IsParentChild: boolean;
    MaxOccurs: number;
    MinOccurs: number;
  }
  export class attachment extends avionbusinessentity {
    constructor() {
      super("7FD70A51-9A67-45DF-91C4-5A0DC470750D");
    }
    AdditionalInfo?: string;
    Content?: string;
    DocumentExtension?: string;
    FileName: string;
    Status: string;
    StorageProviderId?: string;
    SubType: string;
    Type: string;
  }
  export class AvalonDataModel extends avionbusinessentity {
    constructor() {
      super("451BC8AA-45E9-4A87-B1CE-06D677C18F7A");
    }
    DataModelSchema: string;
  }
  export class avalonPage extends avionbusinessentity {
    constructor() {
      super("A6446889-261F-4732-8058-D142C2C755F7");
    }
    AvalonView?: string;
    DetailPage?: string;
    HelpUrl?: string;
    IconPath?: string;
    PageName?: string;
    ParentPage?: string;
    Sequence: number;
    ShowInNavigation?: boolean;
    Title: string;
    Url?: string;
    Version?: string;
  }
  export class avalonView extends avionbusinessentity {
    constructor() {
      super("F7B1C60D-4BD7-48F4-A293-AFEA07D2ACF3");
    }
    ClientSpecific?: boolean;
    CustomViewPath?: string;
    DefaultEditView?: string;
    DefaultSavedSearch?: string;
    DefaultView?: string;
    DetailPage?: string;
    DownloadAllFileName?: string;
    HeaderSearch?: string;
    HelpUrl?: string;
    LayoutType?: string;
    PrimaryEntityTypeId: string;
    ShowDetailsPane?: boolean;
    Title?: string;
    Version?: string;
    ViewModel?: string;
    ViewName: string;
    ViewPath?: string;
    ViewType: string;
  }
  export class avalonViewAction extends avionbusinessentity {
    constructor() {
      super("806B6680-2E6A-4B3E-B6F1-7B92C48B9FC2");
    }
    ActionLabel?: string;
    ActionName: string;
    ActionType?: string;
    ClientSpecific?: boolean;
    CustomCode?: string;
    IconType?: string;
    IsSystemAction?: boolean;
    placement?: string;
    ReportToLaunch?: string;
    Sequence?: number;
    TemplateOverride?: string;
    VisibilityRule?: string;
  }
  export class avalonViewField extends avionbusinessentity {
    constructor() {
      super("BC7361DF-CF1C-4E9C-86CD-584BC2F3D6A7");
    }
    ClientSpecific?: boolean;
    CustomCode?: string;
    DefaultValue?: string;
    FieldLabel: string;
    FieldMask?: string;
    FieldName: string;
    FieldType: string;
    IsRequired: boolean;
    PropertyId?: string;
    SavedSearch?: string;
    SortOrder?: number;
    TemplateOverride?: string;
    ToolTip?: string;
    ValidationRule?: string;
    ViewId?: string;
    ViewPath?: string;
    VisibilityRule?: string;
  }
  export class avalonViewGroup extends avionbusinessentity {
    constructor() {
      super("CAA5EAE5-CAD3-4FE3-8B2F-DA22A4963438");
    }
    ClientSpecific?: boolean;
    DisplayTitle: string;
    GroupName: string;
    GroupType: string;
    LayoutDirective?: string;
    SortOrder: number;
  }
  export class avionConditionalExpression extends avionbusinessentity {
    constructor() {
      super("FF097C3A-353B-4EB9-8361-CE34C7639147");
    }
    AllowedPropertyTypes: string;
    AvailableForUserInput: boolean;
    DisplayTitle?: string;
    MultipleInputValues: boolean;
    OperatorTitle: string;
    QueryPrefix?: string;
    QuerySuffix?: string;
  }
  export class avionDynamicProperty extends avionbusinessentity {
    constructor() {
      super("C5ADF9DE-1613-4262-BFF7-95D386C2E836");
    }
  }
  export class AvionEntityProperty extends avionbusinessentity {
    constructor() {
      super("9C938E70-9E6A-42EA-87A7-99503432718A");
    }
    AllowedEntityTypes?: string;
    AllowExpressionValues?: boolean;
    ChangeTracking?: string;
    ClientSpecificity?: string;
    CollectionEntityType?: string;
    CollectionTypeId?: number;
    DecimalPrecision?: number;
    DecMaxValue?: number;
    DecMinValue?: number;
    DisplayLabel: string;
    DTMaxValue?: Date;
    DTMinValue?: Date;
    ExcludeFromSearch: boolean;
    ExcludeInCloning?: boolean;
    ExpressionContext?: string;
    IntMaxValue?: number;
    IntMinValue?: number;
    IsAbeOnlyProperty?: boolean;
    IsAttachment: boolean;
    IsCollection: boolean;
    IsData: boolean;
    IsDynamicParent?: boolean;
    IsMandatory: boolean;
    IsMultiSelect?: boolean;
    IsParentChildEntity?: boolean;
    IsPersisted: boolean;
    IsRetrieved: boolean;
    IsUTCDateTime?: boolean;
    LookupTable?: string;
    MaxLength?: number;
    PropertyName: string;
    PropertyType?: string;
    SearchContext?: string;
    ToolTip?: string;
    ValidationExpression?: string;
  }
  export class BusinessArea extends avionbusinessentity {
    constructor() {
      super("822CE621-A7E1-45F5-968A-7EB573ED658E");
    }
    DataModelSchema?: string;
    Name: string;
  }
  export class cart extends avionbusinessentity {
    constructor() {
      super("81558E3D-A2C1-44CD-932C-534976EF7569");
    }
    CartTotal?: number;
    ClosureDate?: Date;
    ClosureReason?: string;
    ContactEmail?: string;
    ContactId?: string;
    ContactIPAddress?: string;
    ContactName?: string;
    ContactPhone?: string;
    Details?: string;
    DiscountCode?: string;
    InvoiceForId?: string;
    InvoiceId?: string;
    InvoiceToId?: string;
    PurchaseOrderNumber?: string;
    SettlementOptions?: string;
    Source?: string;
    Status?: string;
    TotalTaxComponent?: number;
  }
  export class cartItem extends avionbusinessentity {
    constructor() {
      super("54AB1A74-58DD-4897-8FDF-C3F1D09449EB");
    }
    Details?: string;
    Header?: string;
    PurchaseContext: string;
    Quantity?: number;
    TotalCost?: number;
  }
  export class cartItemGroup extends avionbusinessentity {
    constructor() {
      super("879DE285-0AA4-4AE4-A7DF-276449115822");
    }
    ItemGroupDescription: string;
    ItemGroupEntityId: number;
    ItemGroupEntityTypeId: number;
    ItemGroupTotal: number;
    ItemQuantity: number;
    TotalTaxComponent?: number;
  }
  export class cashAccount extends avionbusinessentity {
    constructor() {
      super("78FA51D5-4F17-4FC9-99FC-7943EA55BCAA");
    }
    accountDescription: string;
    AccountType: string;
    cashAccountCode: string;
    ccAuthAccountCode: string;
    cscRequiredCS?: boolean;
    cscRequiredOnWeb: boolean;
    glAccount: string;
  }
  export class ChangeLog extends avionbusinessentity {
    constructor() {
      super("C0204FB1-1670-48BD-B81D-740A0081767D");
    }
    EntityName: string;
    ExistingValue?: string;
    NewValue?: string;
    PropertyName: string;
  }
  export class CommunicationHistory extends avionbusinessentity {
    constructor() {
      super("015F6D6A-4E4E-44B5-81B9-54EE4A05EEFF");
    }
    CommunicationDate: Date;
    Details?: string;
    Summary: string;
    Type: string;
  }
  export class communicationPreferences extends avionbusinessentity {
    constructor() {
      super("682F723A-B993-4060-9820-E43579268AE5");
    }
    OptInDate?: Date;
    OptOutDate?: Date;
    Status: string;
    Type: string;
  }
  export class criteriaGroup extends avionbusinessentity {
    constructor() {
      super("5351F633-BB06-42D7-92B8-5FF0A6FAF1E4");
    }
  }
  export class CustomAction_DBSproc extends avionbusinessentity {
    constructor() {
      super("7854089D-CEFD-4796-8771-1A65A81B5CBC");
    }
    SprocName: string;
  }
  export class DataUploadHeader extends avionbusinessentity {
    constructor() {
      super("89840AEA-F053-4624-BDA2-0B29903A58C3");
    }
    FileContents: string;
    RecordCount?: number;
    UploadEntity: string;
    UploadFileName: string;
    ValidationErrors?: string;
  }
  export class DefaultPages extends avionbusinessentity {
    constructor() {
      super("8B447F0F-C46F-4327-9A48-4544861F111C");
    }
    DefaultPage: string;
    Entity: string;
  }
  export class DefaultTimelineFilters extends avionbusinessentity {
    constructor() {
      super("E9971E17-E4A5-4907-A223-AEC67F3E4D5D");
    }
    DefaultFilters: string;
    SecurityGroup: string;
  }
  export class EmailAttachment extends avionbusinessentity {
    constructor() {
      super("9EF7FDD2-34DA-488D-9557-38433F6E5125");
    }
    AttachmentName: string;
    DocumentFormat: string;
    ReportName: string;
    ReportPath: string;
    Type: string;
  }
  export class EmailCommunication extends avionbusinessentity {
    constructor() {
      super("016E3CF0-3F3F-4411-8B07-08E5DBEB0C80");
    }
    BCC?: string;
    CC?: string;
    EmailSubject?: string;
    EmailTemplate?: string;
    FromAddress?: string;
    MessagePriority: string;
    MessageTemplate?: string;
    Priority?: string;
    RecipientEmail?: string;
    RecipientList?: string;
    ReplyToAddress?: string;
    ScheduledDate?: Date;
    Status?: string;
  }
  export class EmailTemplate extends avionbusinessentity {
    constructor() {
      super("7989E956-1698-4AF6-B24C-6ED172C9573F");
    }
    TemplateContent?: string;
    TemplateName: string;
  }
  export class Enquiry extends avionbusinessentity {
    constructor() {
      super("772536F7-D259-48D7-85E4-70261DC2433B");
    }
    Channel?: string;
    Counsellor?: string;
    CountryResidence?: string;
    CurrentGrade?: string;
    Curriculum?: string;
    EnquiryDate: Date;
    EventDetail?: string;
    EventSource?: string;
    ExpectedGraduationYear?: number;
    HowHear: string;
    Message?: string;
    Nationality?: string;
    SchoolName?: string;
    SourceCode: string;
    StudyCourse?: string;
    StudyIntake?: string;
  }
  export class EntityRelationship extends avionbusinessentity {
    constructor() {
      super("4F3440D9-D7E2-4AC2-84C3-4C5A1DE1DF21");
    }
    Entity: string;
    ExcludeInCloning?: boolean;
    MaxOccurs: number;
    MinOccurs: number;
    PrimaryProperty?: string;
    RelatedEntity: string;
    RelationshipType: string;
  }
  export class Expenses extends avionbusinessentity {
    constructor() {
      super("CB33EBB1-EAA4-4865-85A0-1297A8ACD708");
    }
    ActualAmount?: number;
    BudgetedAmount?: number;
    Details?: string;
    Type: string;
  }
  export class expression extends avionbusinessentity {
    constructor() {
      super("007E1C4D-97D1-4ACB-B3BF-F699BDE7E16A");
    }
  }
  export class FinancialEntity extends avionbusinessentity {
    constructor() {
      super("DF2F4763-03BA-4443-90CF-FBA53CC6DAE5");
    }
    Code: string;
    ExtraOne?: string;
    ExtraThree?: string;
    ExtraTwo?: string;
    Name: string;
  }
  export class financialTransaction extends avionbusinessentity {
    constructor() {
      super("CE64BB60-FB06-4E3C-B6F9-4261D6088F5F");
    }
    Amount: number;
    CCCVV?: string;
    CCExpYear?: number;
    CCName?: string;
    CCNumber?: string;
    ChequeNumber?: string;
    CreditCardExpiryMonth?: string;
    CreditCardType?: string;
    Details?: string;
    Distribution?: string;
    GatewayReference?: string;
    GatewayResponseDetails?: string;
    ReversalDateTime?: Date;
    ReversedBy?: string;
    ReverseTransaction?: boolean;
    ReverseTransation?: boolean;
    Status: string;
    SubType: string;
    TxnContactEmail?: string;
    TxnContactId?: string;
    TxnContactName?: string;
    TxnContactPhone?: string;
    TxnDate?: Date;
    TxnDescription?: string;
    Type: string;
  }
  export class FinancialTxnDistribution extends avionbusinessentity {
    constructor() {
      super("B2418C38-28F8-4396-9B82-56DED5ED12F2");
    }
    Amount: number;
    DistributionType?: string;
    FxId: string;
    LineItem: string;
    LineItemId: string;
  }
  export class FollowupRequest extends avionbusinessentity {
    constructor() {
      super("A40B5539-7DBE-412F-A9DB-8A241A8B4D09");
    }
    ActionedByEntity?: string;
    ActionTaken?: string;
    ActionType?: string;
    AssignedToEntity?: string;
    DateActioned?: Date;
    DateRequested?: Date;
    FollowupDate?: Date;
    RelatedParentEntity?: string;
    RequestDetails?: string;
    RequestedByEntity?: string;
    Status: string;
    Type: string;
  }
  export class fX extends avionbusinessentity {
    constructor() {
      super("298DA10E-9DC7-4219-9316-B652AE02EBAA");
    }
    Details: string;
    EntityIdentifier: number;
    FilterDefinition: string;
    FilterExpression: string;
    GridDefinition: string;
    Name: string;
    PublicFX: boolean;
  }
  export class GlobalConfiguration extends avionbusinessentity {
    constructor() {
      super("8E730411-DAE7-4543-A5C1-01E183A659E1");
    }
    ConfigKey: string;
    ConfigValue: string;
  }
  export class IDInfo extends avionbusinessentity {
    constructor() {
      super("F5BF2831-910B-414E-ACFC-E9A4141B96C9");
    }
    IDNumber: string;
    Type: string;
  }
  export class ImportDefinition extends avionbusinessentity {
    constructor() {
      super("BA54DBBA-34A0-4F1C-915D-855938DF097B");
    }
    SourceFileName?: string;
    Title?: string;
  }
  export class ImportDefinitionMapping extends avionbusinessentity {
    constructor() {
      super("D76394FD-DF6C-4DDE-8116-FA53AF7618C1");
    }
    DestinationEntityType?: number;
    DestinationProperty?: string;
    SourceColumn?: string;
  }
  export class ImportJob extends avionbusinessentity {
    constructor() {
      super("2E3C42CE-0335-4620-9B0B-5B0E1E6840C8");
    }
    CompletionDateTime?: Date;
    FailedRecordCount?: number;
    ScheduleDateTime?: Date;
    SourceFileName?: string;
    SourceFileRecordCount?: number;
    Status: string;
    SuccessfulRecordCount?: number;
  }
  export class individual extends avionbusinessentity {
    constructor() {
      super("A11AE016-8E6E-4341-A4E7-D1848967897B");
    }
    ABN?: string;
    BirthDate?: Date;
    CurrentStatus?: string;
    Deregistered?: boolean;
    EmailAddress?: string;
    Extra1?: string;
    Extra2?: string;
    Extra3?: string;
    Extra4?: string;
    FirstName: string;
    Gender?: string;
    JobTitle?: string;
    LastName: string;
    MarketingEmail?: string;
    MiddleName?: string;
    OrganisationName?: string;
    PasswordResetCompleted?: boolean;
    PreferredName?: string;
    SourceCode?: string;
    Spouse?: string;
    Status?: string;
    SubType?: string;
    TFN?: string;
    Title?: string;
    Type?: string;
    WebUserId?: string;
  }
  export class Installment extends avionbusinessentity {
    constructor() {
      super("D83703FB-F283-416E-899C-68BC4EE16DDA");
    }
    AmountExpression?: string;
    BuyerType: string;
    Criteria?: string;
    EligibilityCriteria?: string;
    EndDate?: Date;
    ProductCriteria?: string;
    StartDate?: Date;
    Title?: string;
    TotalAmount?: number;
    TotalInstallments?: number;
    Type: string;
  }
  export class InstallmentSchedule extends avionbusinessentity {
    constructor() {
      super("27C6FB84-5735-4C9B-8A70-48DB020FD3E0");
    }
    Amount_Percentage: number;
    AmountDue: number;
    InstallmentDate?: Date;
  }
  export class InteractionRules extends avionbusinessentity {
    constructor() {
      super("4A3D3893-7FBB-4DFA-B7C3-747BFC5DAC8E");
    }
    Category: string;
    InteractionProperty: string;
    IsAvailable?: boolean;
    IsRequired?: boolean;
    SubType: string;
    Type: string;
  }
  export class Interactions extends avionbusinessentity {
    constructor() {
      super("C17FFB6A-5894-4A42-8E24-AC9945C5DDC9");
    }
    ABMRFSubmitted?: Date;
    ABPMRSSignedForm?: boolean;
    ABRoomType?: string;
    ABSDRDateReceived?: Date;
    ABSDRDateReturned?: Date;
    ABVisaNumber?: number;
    ACBCBRCaseEscalated?: boolean;
    ACBCBRDateIssued?: Date;
    ACBCBREvicted?: string;
    ACBIRFIncidentDate?: Date;
    ACBIRFIncidentType?: string;
    ACBNLDateIssued?: Date;
    ACBSFFFineDate?: Date;
    ACBSFFFineType?: string;
    ACBWLDateIssued?: Date;
    ADCFSignedForm?: boolean;
    ADCIFCheckinDate?: Date;
    ADCIFSignedDeclaration?: boolean;
    ADCOFCheckoutDate?: Date;
    ADCOFSignedDeclaration?: boolean;
    ADCPFSignedBy?: string;
    ADCPFSignedForm?: boolean;
    ADDeferralReason?: string;
    ADDRDate?: Date;
    ADECFDate?: Date;
    ADECFReason?: string;
    ADECSigned?: string;
    ADPIDDocumentType?: string;
    AMRCRLReferralCase?: string;
    AMRMQMedicalIssue?: boolean;
    AMRMQSubmitted?: boolean;
    ARCFDate?: Date;
    ARCNFDate?: Date;
    ARCNFInformationUpdated?: string;
    ARCRFRequestDate?: Date;
    ARCRFStatus?: string;
    ARDSSDate?: Date;
    AREDate?: Date;
    AREReason?: string;
    ARERSDate?: Date;
    ARERSType?: string;
    ARLeaveDate?: Date;
    ARLeaveReason?: string;
    ARMCFRequestDate?: Date;
    ARMCFStatus?: string;
    ARMFSubmissionDate?: Date;
    ARPCFRequestDate?: Date;
    ARPCFStatus?: string;
    ARPPFSubmissionDate?: Date;
    ARSLType?: string;
    ASPOtherType?: string;
    ASPPDType?: string;
    Category: string;
    CBRDate?: Date;
    CBRType?: string;
    CFDate?: Date;
    CFStatus?: string;
    CollectionDate?: Date;
    CTFCampusTransferTo?: string;
    CTFTransferAY?: string;
    CTFTransferDate?: Date;
    CTFTransferLocation?: string;
    CTFTransferReason?: string;
    DDRDate?: Date;
    DDROutcome?: string;
    Details?: string;
    EFExpulsionDate?: Date;
    EFReason?: string;
    EIDExpiryDate?: Date;
    GraduationDate?: Date;
    GraduationStatus?: string;
    GSFGraduationDate?: Date;
    GSFStatus?: string;
    HDRDLDate?: Date;
    HDRDLDoctorIssued?: string;
    HDRHRFCompletionDate?: Date;
    HDRHRFHI?: string;
    HDRSDDDisability?: string;
    HDRSDDStatus?: string;
    KHDAComplete?: string;
    PCExpiryDate?: Date;
    PCNationality?: string;
    SARIFClub?: string;
    SARIFFormReceived?: Date;
    SARIFSport?: string;
    SARTSTrainingDetail?: string;
    SDCACCollected?: string;
    SDCACDate?: Date;
    SDCAtCDateReceived?: Date;
    SDCAtCDateSent?: Date;
    SDCAtCDocumentAttested?: string;
    SDCAtCNotificationSent?: string;
    SDCAtCType?: string;
    SFRCSDate?: Date;
    SFRCSSemester?: string;
    SFRLDate?: Date;
    SOARCDateSubmitted?: Date;
    SOOTRDateSubmitted?: Date;
    SRRLReason?: string;
    SubType: string;
    TBBFDate?: Date;
    TBBFLocation?: string;
    TBBFReceived?: boolean;
    TBBFRoomNumber?: string;
    TBCDDate?: Date;
    TBCDFormsReceived?: boolean;
    TBDDDriverName?: string;
    TPWFDate?: Date;
    TPWFReasonForWithdrawal?: string;
    TransferDate?: Date;
    TransferLocation?: string;
    TransferReason?: string;
    Type: string;
    VCExpiryDate?: Date;
    VCIssuer?: string;
  }
  export class InteractionSecurity extends avionbusinessentity {
    constructor() {
      super("003EE99C-FECC-44D6-88B0-3EEC59E2EB3F");
    }
    Category: string;
    SecurityGroup: string;
    SubType?: string;
    Type?: string;
  }
  export class invoice extends avionbusinessentity {
    constructor() {
      super("E2446D0F-21DA-420D-BB0A-A556C4ED14ED");
    }
    AmountDue?: number;
    AmountPaid?: number;
    Balance?: number;
    CartID?: string;
    Category?: string;
    Currency?: string;
    Details?: string;
    DueDate?: Date;
    InstalmentAvailable?: boolean;
    InstalmentSelected?: boolean;
    InvoiceContactEmail?: string;
    InvoiceContactID?: string;
    InvoiceContactName?: string;
    InvoiceContactPhone?: string;
    InvoiceDate: Date;
    InvoiceForID?: string;
    PaymentTerms: string;
    PurchaseOrderNumber?: string;
    Status: string;
    SubscriptionRunDescription?: string;
    SubscriptionRunID?: number;
    TotalTaxComponent?: number;
    Type: string;
  }
  export class invoiceLineItem extends avionbusinessentity {
    constructor() {
      super("0E116FFE-D62F-4725-B185-2E51B59F6563");
    }
    AmountDue?: number;
    AmountPaid?: number;
    AppliedPriceRule?: string;
    CartItemId?: string;
    GLCode?: string;
    Installment?: string;
    ItemCode?: string;
    ItemContacctName?: string;
    ItemContact_AccessReq?: string;
    ItemContact_DietReq?: string;
    ItemContactEmail?: string;
    ItemContactId?: string;
    ItemContactPhone?: string;
    ItemContext?: string;
    ItemDescription?: string;
    LineTotal: number;
    OptionalItem?: boolean;
    Quantity: number;
    SystemPrice?: number;
    SystemPriceRule?: string;
    TaxAmount: number;
    TotalTaxComponent?: number;
    UnitPrice: number;
  }
  export class InvoiceLineItemOptions extends avionbusinessentity {
    constructor() {
      super("8CEBA804-6FB3-4DE3-94DC-F7D0E30E22CB");
    }
    OptionDetails: string;
    OptionSequence?: number;
    OptionType: string;
  }
  export class invoiceSubLineItem extends avionbusinessentity {
    constructor() {
      super("C274F7F0-079C-4142-A09E-2717270ACDFA");
    }
    AmountDue?: number;
    AmountPaid?: number;
    BillingContact?: string;
    DueDate?: Date;
  }
  export class ledger extends avionbusinessentity {
    constructor() {
      super("BA2CAABC-B9D3-44A4-B4DA-3C729528F89C");
    }
    Amount: number;
    Details: string;
    EntrySource: string;
    EntrySourceID?: number;
    EntryType: string;
    GLAccount: string;
  }
  export class LedgerCodes extends avionbusinessentity {
    constructor() {
      super("A9460098-01D5-40A4-9C70-2E79833DC607");
    }
    ExtraOne?: string;
    ExtraThree?: string;
    ExtraTwo?: string;
    GLCode: string;
    GLDesciption: string;
  }
  export class LifecycleEvents extends avionbusinessentity {
    constructor() {
      super("4032500A-7A6C-43B6-826E-C86B4DF76D03");
    }
    ExistingCategory?: string;
    ExistingStatus?: string;
    NewCategory: string;
    NewStatus: string;
    Notes?: string;
    SystemGenerated?: boolean;
    UpdateDate: Date;
  }
  export class MarketingRelationship extends avionbusinessentity {
    constructor() {
      super("1D14D516-4771-4192-9DE9-B3F953CC905F");
    }
    ApprovalFee: number;
    EndDate: Date;
    IsActive?: boolean;
    Marketer: string;
    ProvisionalFee: number;
    StartDate: Date;
  }
  export class MergeLog extends avionbusinessentity {
    constructor() {
      super("9B03651F-1D0F-481C-B16B-9A2D90F5D307");
    }
    MasterEntityId: string;
    MergeEntityIDList: string;
    MergeEntityTypeId: string;
  }
  export class note extends avionbusinessentity {
    constructor() {
      super("38DFD598-158A-47E5-AB56-9D25625C13FC");
    }
    Note: string;
    Status: string;
    SubType?: string;
    Type: string;
  }
  export class organisation extends avionbusinessentity {
    constructor() {
      super("796D42F9-2EF8-4633-A15D-9E581F61F19C");
    }
    OrganisationName: string;
    Status: string;
    SubType?: string;
    Type: string;
  }
  export class ParamMap extends avionbusinessentity {
    constructor() {
      super("CBAED06B-416E-4D74-9C08-9F7D01404C95");
    }
    MappedProperty?: string;
    MappingType: string;
    ParamEntity?: string;
    PropertyValue?: string;
  }
  export class PaymentGatewayConfiguration extends avionbusinessentity {
    constructor() {
      super("EE6D700B-97FF-4187-BAE9-8C2631E0953F");
    }
    AccountPassword: string;
    EndpointAddress: string;
    MerchantId: string;
    Mode: string;
    Name: string;
  }
  export class phone extends avionbusinessentity {
    constructor() {
      super("F230DBDD-DA1F-4174-BB7B-F67FA657B888");
    }
    Extension?: string;
    IsPrimary: boolean;
    PhoneNumber: string;
    type: string;
  }
  export class PriceRange extends avionbusinessentity {
    constructor() {
      super("60D3A181-ADE8-46CF-852D-EF0C941A94ED");
    }
    Price?: number;
    RangeMax?: number;
    RangeMin?: number;
    ThresholdAmount?: number;
    Type?: string;
  }
  export class pricingRule extends avionbusinessentity {
    constructor() {
      super("71EFA95F-6FD2-4D81-8171-C202B338F578");
    }
    Amount: number;
    AmountExpression?: string;
    BillingEntity: string;
    BuyerType: string;
    EligibilityCriteria?: string;
    EndDate: Date;
    InstallmentOption?: string;
    Name: string;
    PurchaseContext?: string;
    SelectionCriteria?: string;
    StartDate: Date;
    Status: string;
    TaxPercentage: number;
    Type: string;
    ValueProperty?: string;
  }
  export class PricingTable extends avionbusinessentity {
    constructor() {
      super("7419F221-8944-4CFF-8E5F-FC64D17D8A03");
    }
    Amount: number;
    BillDate?: Date;
    Details?: string;
    PaidThruDate?: Date;
    PriceKey: string;
    ProductId: number;
    SubscriptionRunId: number;
  }
  export class product extends avionbusinessentity {
    constructor() {
      super("B56700E0-6302-4695-84AC-BC9690EC4CDB");
    }
    AllowInstallments?: boolean;
    AllowSubscriptions: boolean;
    Category: string;
    DefaultGLCode: string;
    DefaultPrice?: number;
    Details: string;
    Name: string;
    ProductCode: string;
    PurchaseDetails?: string;
    ShowinOnlineStore: boolean;
    Status: string;
    SubType: string;
    TaxPercentage?: number;
    Type: string;
  }
  export class productPrice extends avionbusinessentity {
    constructor() {
      super("0C0F231F-F92A-4F98-B64E-E36CF4DC8B7A");
    }
    details: string;
    status: string;
    title: string;
    unit_price: number;
  }
  export class PurchaseDetail_Product extends avionbusinessentity {
    constructor() {
      super("E1E9616B-1A80-4D94-BE9A-A139F927949F");
    }
    AppliedPrice: number;
    InstalmentAvailable?: boolean;
    InstalmentSelected?: boolean;
    ItemContact: string;
    ItemContactEmail?: string;
    ItemContactName?: string;
    ItemContactPhone?: string;
    ItemDescription?: string;
    ItemDetails: string;
    PriceOverrideUser?: string;
    ProductPurchased?: string;
    Quantity: number;
    SystemPrice?: number;
    SystemPriceRule?: string;
    Taxable: boolean;
    TotalInstalmentAmount?: number;
  }
  export class PurchaseDetail_Subscription extends avionbusinessentity {
    constructor() {
      super("F8449076-C7BF-4C54-A326-5A01107501A5");
    }
    AppliedPrice?: number;
    InstalmentAvailable?: boolean;
    InstalmentSelected?: boolean;
    IsApplicable?: boolean;
    IsSelected?: boolean;
    ItemContact: string;
    ItemContactEmail?: string;
    ItemContactName?: string;
    ItemContactPhone?: string;
    ItemDescription?: string;
    ItemDetails: string;
    PriceOverrideUser?: string;
    Quantity: number;
    SystemPrice?: number;
    SystemPriceRule?: string;
    Taxable?: boolean;
    TotalInstalmentAmount?: number;
  }
  export class PurchaseHeader_Product extends avionbusinessentity {
    constructor() {
      super("83E24569-A00E-4F5E-B83D-98FDC5864AA6");
    }
    Code?: string;
    ProductDetails: string;
    PurchaseDescription?: string;
    Quantity?: number;
    TotalCostExclTax?: number;
  }
  export class PurchaseHeader_Subscription extends avionbusinessentity {
    constructor() {
      super("63B48FD3-3A97-47E2-8126-F708487EE171");
    }
    Code?: string;
    ProductDetails: string;
    PurchaseDescription?: string;
    Quantity: number;
    TotalCostExclTax?: number;
  }
  export class QuestionCategory extends avionbusinessentity {
    constructor() {
      super("4B48D476-B4E3-4692-A26C-65963FCB4855");
    }
    details?: string;
    name: string;
    position: number;
  }
  export class QuestionResponse extends avionbusinessentity {
    constructor() {
      super("7D9B7D23-4BE7-4B66-860D-AFA5B18AC3D3");
    }
    IsValid?: boolean;
    JumpToQuestion?: string;
    position?: number;
    response_text?: string;
  }
  export class RelatedEntity extends avionbusinessentity {
    constructor() {
      super("F13A051F-E419-403E-806A-CD2B97A04705");
    }
    RelatedEntityDescription?: string;
    RelatedEntityId: number;
    RelatedEntityTypeId: number;
    RoleDescription?: string;
    RoleType: string;
  }
  export class ReportDefinition extends avionbusinessentity {
    constructor() {
      super("FA872C5D-7726-4A55-8C42-22898DB9028F");
    }
    AttachmentFormat?: string;
    DownloadFileName?: string;
    PrimaryEntityTypeId?: string;
    ReportCategory: string;
    ReportCode?: string;
    ReportDetails?: string;
    ReportPath: string;
    ReportTitle: string;
    Server?: string;
    SubCategory: string;
  }
  export class ReportParams extends avionbusinessentity {
    constructor() {
      super("5EBBE7B1-CD2F-4F22-B1C5-05CE9B2D3579");
    }
    Caption?: string;
    ComparisonOperator?: string;
    ContextValue?: string;
    DataType?: string;
    DefaultValue?: string;
    DisplayForInput?: boolean;
    DisplayForMapping?: boolean;
    FieldName?: string;
    IsRequired?: boolean;
    MappingType?: string;
    ParameterName: string;
    ParameterValue?: string;
    Sequence?: number;
  }
  export class ReportServer extends avionbusinessentity {
    constructor() {
      super("E6163857-01FE-4D9C-BBD6-A7EB7FBD2432");
    }
    ReportServerUrl: string;
    UserDomain?: string;
    Username: string;
    UserPwd: string;
  }
  export class ResponseHeader extends avionbusinessentity {
    constructor() {
      super("651326FB-ED87-4311-B997-AA80057D455A");
    }
    device_desc: string;
    device_id: string;
    device_os: string;
    end_time?: Date;
    ip_address: string;
    ProgrammeOfStudy?: string;
    start_time: Date;
  }
  export class Responses extends avionbusinessentity {
    constructor() {
      super("46427467-1FB0-4FD9-85FC-38DC910D10D4");
    }
    question_id?: string;
    response_id?: string;
    response_value?: string;
  }
  export class rule extends avionbusinessentity {
    constructor() {
      super("B172375A-24CF-41A1-83D8-B7810DC56056");
    }
    IsActive: boolean;
    PrimaryEntityType: string;
    RuleName: string;
  }
  export class ruleData extends avionbusinessentity {
    constructor() {
      super("243FF324-34C2-48C4-AADD-B8159CDD9C01");
    }
    MatchLength: number;
    ObjectName: string;
    PropertyName: string;
  }
  export class ruleGroup extends avionbusinessentity {
    constructor() {
      super("FF5F970E-1952-4752-B191-14D6102D9FC3");
    }
    RuleGroupName: string;
  }
  export class savedSearch extends avionbusinessentity {
    constructor() {
      super("8CE2EBA6-34DD-424C-B4A1-72987134B1A6");
    }
    Category?: string;
    Details?: string;
    DirectSqlTxt?: string;
    FunctionalArea: string;
    IsPublic: boolean;
    IsSystem?: boolean;
    Name: string;
    RecordSpecificSql?: string;
    SearchDefinition?: string;
    SearchEntityTypeId?: string;
    SqlWithDefaultParamVals?: string;
    SubCategory?: string;
    SysSavedSearch?: boolean;
    Type: string;
    ViewSproc?: string;
  }
  export class ScheduledAction extends avionbusinessentity {
    constructor() {
      super("C7D2BEB9-CC46-4FF3-A3FD-3BFB0BD09B79");
    }
    ActionContext: string;
    ActionDetails: string;
    Details?: string;
    Name: string;
    ScheduleDetails: string;
  }
  export class ServiceRequest extends avionbusinessentity {
    constructor() {
      super("34CBC04A-29C7-4BE0-8046-4A15D428C9B5");
    }
    Details: string;
    NewCourse?: string;
    OriginalApplication?: string;
    RequestDateTime: Date;
    RequestOwner: string;
    RequestType: string;
    ResolutionDateTime?: Date;
    Status: string;
  }
  export class Site extends avionbusinessentity {
    constructor() {
      super("D874FEDF-4B4C-4BA3-AC93-6CADE0C4AE18");
    }
    ABN?: string;
    DefaultGLCode?: string;
    Details: string;
    HomePageUrl?: string;
    Name: string;
  }
  export class SortKeys extends avionbusinessentity {
    constructor() {
      super("C40D2DF9-0532-4846-93A9-E441DECDE0EB");
    }
    Sequence: number;
    SortKey: string;
    SortOrder: string;
  }
  export class subscription extends avionbusinessentity {
    constructor() {
      super("17CBBEE4-39BB-440D-B7A6-7E1B2BF778A4");
    }
    BillDate: number;
    BillMonth: string;
    Details: string;
    InternalContactEmail: string;
    Name: string;
    PrebillDays: number;
    PrebillReportDays: number;
    Status: string;
    SubType: string;
    Term: number;
    Type: string;
  }
  export class subscriptionProduct extends avionbusinessentity {
    constructor() {
      super("E5271B95-8779-4095-AF38-D322AF6FC2E0");
    }
    ApplicabilityContext?: string;
    DefaultPrice: number;
    GLCode?: string;
    Mandatory?: boolean;
    Product: string;
    ProductGroup?: string;
    SortOrder?: number;
    TaxPercentage?: number;
  }
  export class subscriptionProductGroup extends avionbusinessentity {
    constructor() {
      super("04B15389-8F6F-459A-911F-3E843349D443");
    }
    AllowMultipleSelection?: boolean;
    Details: string;
    IsMandatory: boolean;
    Name: string;
    SortOrder?: number;
  }
  export class subscriptionRun extends avionbusinessentity {
    constructor() {
      super("898D62E0-6883-4AAE-8855-4D7D2D77E297");
    }
    EmailCommunication?: string;
    Name: string;
    RunDate: Date;
    Status: string;
    TotalAmountInvoiced?: number;
    TotalBillableAmount?: number;
    TotalInvoicesRaised: number;
    TotalTaxAmount?: number;
    Type: string;
  }
  export class subscriptionRunOutput extends avionbusinessentity {
    constructor() {
      super("5D1344A5-6B46-4DCF-97DA-5A5659A858D6");
    }
    Amount?: number;
    BillTo?: string;
    PriceRuleInfo?: string;
    ProductInfo?: string;
  }
  export class SubscriptionRunResults extends avionbusinessentity {
    constructor() {
      super("4FC1F486-D64D-4837-BA4F-9A23F2B42A41");
    }
  }
  export class Survey extends avionbusinessentity {
    constructor() {
      super("4A324E80-95F8-40C9-986C-7C2628D5C69C");
    }
    code: string;
    completion_text?: string;
    details?: string;
    MinRespondentsPercentage?: number;
    name: string;
  }
  export class SurveyQuestion extends avionbusinessentity {
    constructor() {
      super("B5187A65-1710-4691-A9D5-2D9D89F420E6");
    }
    Category?: string;
    IsOptional?: boolean;
    JumpToQuestion?: string;
    position: number;
    question_text?: string;
    QuestionTitle?: string;
    response_max_length?: number;
    response_range_max?: number;
    response_range_min?: number;
    response_type: string;
    ResponseCriteria?: string;
  }
  export class SurveyRun extends avionbusinessentity {
    constructor() {
      super("406A29F3-03F6-406C-9BEE-6F2D71667322");
    }
    allow_multiple_submissions: boolean;
    completion_text?: string;
    details: string;
    end_time?: Date;
    execution_code: string;
    MaxResponses?: number;
    MinRespondentsPercentage?: number;
    qr_code_path?: string;
    response_rate_percent?: number;
    start_time: Date;
    total_responses?: number;
    Survey: string;
    Status: string;
  }
  export class sysconfigsetting extends avionbusinessentity {
    constructor() {
      super("AABDA218-A2F8-4ED4-8504-2CF2141B727C");
    }
  }
  export class SysGroup extends avionbusinessentity {
    constructor() {
      super("C79ABEDF-DB9B-442E-BF5A-8DA045AAED22");
    }
    EmailAddress?: string;
    GroupEmail?: string;
    GroupName: string;
    HomeScreen?: string;
  }
  export class SysPermission extends avionbusinessentity {
    constructor() {
      super("B1FD0D6E-E748-4144-B5EC-73DF81C78BBA");
    }
    AbeMethod?: string;
    Allowed: boolean;
    Detail?: string;
    IsDenied?: boolean;
    IsReadOnly?: boolean;
    TargetEntity?: string;
  }
  export class SysUser extends avionbusinessentity {
    constructor() {
      super("6D6C84AF-A441-40C3-9C95-353083BE6A3F");
    }
    EmailAddress?: string;
    FirstName: string;
    FullName?: string;
    LastName: string;
    Password: string;
    SiteId?: string;
    UserName: string;
    WebUserId?: string;
    WindowsAccount?: string;
  }
  export class TaskSchedule extends avionbusinessentity {
    constructor() {
      super("06EFF81B-4688-43B9-9F2E-398F70423881");
    }
    day_of_month?: string;
    day_of_week?: string;
    Name: string;
    recurrence_expression?: string;
    recurrence_hours?: string;
    recurrence_minutes?: string;
    recurrence_month?: string;
  }
  export class TriggeredAction extends avionbusinessentity {
    constructor() {
      super("4F071C0A-A6BD-4DA0-9A14-DA79DDB7E0F9");
    }
    ActionContext: string;
    ActionDetails?: string;
    EntityContext: string;
    ExecutionCondition?: string;
    ExecutionSequence: number;
    Method: string;
    SysAction?: boolean;
    TriggerPoint: string;
  }
  export class VersionDependancy extends avionbusinessentity {
    constructor() {
      super("1577A2CA-54C0-4C2F-B25C-7BCC2E28A05F");
    }
    DBVersion: string;
    GenesisVersion: string;
    UIVersion: string;
  }
  export class VersionHistory extends avionbusinessentity {
    constructor() {
      super("CE6E6954-E84E-43B9-94D0-61E63B7E7395");
    }
    Component: string;
    ReleaseDate: Date;
    ReleaseNotes: string;
    Version: number;
  }
  export class WorkflowAction extends avionbusinessentity {
    constructor() {
      super("17169B60-A027-4D4E-9B27-6AA87E09777F");
    }
    ActionDetails: string;
    ActionName: string;
    ActionType: string;
    IsEnabled?: boolean;
    Sequence: number;
  }
  export class WorkflowActionPropertyMapping extends avionbusinessentity {
    constructor() {
      super("3945EC9E-3A43-406D-9DF6-9836727553EF");
    }
    MappedProperty: string;
    MappingType?: string;
    PropertyValue?: string;
  }
  export class WorkflowContext extends avionbusinessentity {
    constructor() {
      super("47D14CF5-DD60-4C08-82DB-6DB3C61391CF");
    }
    ContextMethod: string;
    ContextTrigger: string;
    EntityContext: string;
    ExecutionCondition: string;
  }
  export class WorkflowTask extends avionbusinessentity {
    constructor() {
      super("418994B5-FCF2-48F7-B41A-1060EA405A8D");
    }
    Context?: string;
    ContextCondition?: string;
    ContextMethod: string;
    ContextTrigger: string;
    Details: string;
    IsEnabled?: boolean;
    RecurrenceExpression?: string;
    SysWorkflow?: boolean;
    TaskName: string;
    TaskSchedule?: string;
    TaskType?: string;
  }
}
