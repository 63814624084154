import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppHelperService } from '../../services/app-helper.service';

@Component({
  selector: 'app-unauthorised',
  templateUrl: './unauthorised.component.html',
  styleUrls: ['./unauthorised.component.scss']
})
export class UnauthorisedComponent implements OnInit {
  constructor(private router: Router, private appHelper: AppHelperService) { }
  loginUrl: string = "";
  ngOnInit() {
  }
}
