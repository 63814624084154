import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AvalonModels } from 'src/app/models/avalon.models';
import { AppHelperService } from 'src/app/services/app-helper.service';
import { SalModels } from './../../models/sal.models';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  loggedInIndividual: AvalonModels.individual;
  surveyRuns: SalModels.SalSurveyRun[];
  
  lvInProgress: string;
  lvPending: string;
  lvCompleted: string;
  lvCancelled: string;
  lvExpired: string;

  constructor(private appHelper: AppHelperService, private router: Router) { }

  ngOnInit(): void {
    this.appHelper.setQuestion(null);
    this.appHelper.getLoggedInIndividual()
                  .pipe(
                    mergeMap(i=>{
                      this.loggedInIndividual = i;
                      return this.appHelper.genesisService.listByOwner(this.loggedInIndividual, new SalModels.SalSurveyRun());
                    }),
                    map(
                      srs=>{
                        srs.forEach(s=>s.SurveyEntity = this.getSurveyName(s.Survey));
                        this.surveyRuns = srs.sort((a, b) => (a.SurveyEntity > b.SurveyEntity) ? 1 : 0);
                      }
                    )
                  ).subscribe();
      let srStatus = this.appHelper.lookups.find(l=>l.TableName.toLowerCase() == "surveyrun.status");
      srStatus.Values.forEach(v=>{
        switch (v.LookupName) {
          case "In Progress":
            this.lvInProgress = v.Id;
            break;
          case "Completed":
            this.lvCompleted = v.Id;
            break;
          case "Pending":
            this.lvPending = v.Id;
            break;
          case "Expired":
            this.lvExpired = v.Id;
            break;
          case "Cancelled":
            this.lvCancelled = v.Id;
            break;
          default:
            break;
        }
      })
  }

  startSurvey(sr: SalModels.SalSurveyRun) {
    let survey = this.appHelper.getEntityObject(sr.Survey);
    this.appHelper.surveyRun = sr;
    this.router.navigate(["/survey", survey.DisplayId]);
  }



  getSurveyName(surveyEP: string) : Observable<string> {
    let surveyObj = <AvalonModels.Survey> this.appHelper.getEntityObject(surveyEP);
    return this.appHelper.genesisService.readAbe<AvalonModels.Survey>(surveyObj)
                                  .pipe(
                                    map(s=>{
                                      return s.name
                                    })
                                  );
  }


}
