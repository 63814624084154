import { Pipe, PipeTransform } from '@angular/core';
import { AppHelperService } from '../services/app-helper.service';
/*
 * Raise the value exponentially
 * Takes an guid and returns lookup name.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ model.LookupPropName | lookupName }}
 *   formats to: LookupName from Avalon
*/
@Pipe({name: 'lookupName'})
export class LookupNamePipe implements PipeTransform {
    constructor(private appHelper: AppHelperService) {
        
    }

    transform(value: string): string {
        if(this.appHelper.lookups == null){
            return "";
        }
        var lookupName = this.appHelper.lookups.filter(
            x=>{
                var isMatch = false;
                var match = x.Values.find(v=>v.Id == value);
                return match != null;
            }
        ).map(x=>{
            var match = x.Values.find(v=>v.Id == value);
            return match.LookupName;
        });
        if(lookupName.length == 0){
            return value;
        } else {
            return lookupName[0];
        }
    }
}