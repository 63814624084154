import {AvalonModels} from './avalon.models';
import {Observable} from 'rxjs'

export namespace SalModels {
    export class SalSurveyRun extends AvalonModels.SurveyRun {
        SurveyEntity: Observable<any>;
    }

    export class SalSurvey extends AvalonModels.Survey {
        questions: SalModels.SalSurveyQuestion[] = [];
    }

    export class SalSurveyQuestion extends AvalonModels.SurveyQuestion {
        responses: SalModels.SalQuestionResponse[] = [];
        selectedResponseValue: any;
        questionType: QuestionType;
        isComplete: boolean = false;
    }

    export class SalQuestionResponse extends AvalonModels.QuestionResponse {
        isSelected: boolean = false;
    }

    export class SalResponseHeader extends AvalonModels.ResponseHeader {
        responses: AvalonModels.Responses[] = [];
    }

    export enum QuestionType {
        MultipleChoice,
        SingleChoice,
        FreeText,
        Information
    }
}