import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppHelperService } from './app-helper.service';
import { GenesisProviderService } from './genesis-service-provider.service';
import { Injectable } from '@angular/core';
import { map, concatMap } from 'rxjs/operators';

@Injectable()
export class AccessControlActivate implements CanActivate {
    constructor(private appHelper: AppHelperService, private router: Router){

    }
    
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
      ): Observable<boolean>|Promise<boolean>|boolean {

        if (this.appHelper.genesisService.sysUsr == null) {
          this.router.navigate(['/']);
          return of(false);
        }

        return of(true);
      }
}
