import { Component, OnInit } from '@angular/core';
import { AppHelperService } from 'src/app/services/app-helper.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  constructor(private loaderService: LoaderService,  private appHelper: AppHelperService) {
    this.loaderService.isLoading.subscribe(
      (v)=>{
        this.loading = v;
      }
    )
    this.loaderService.errors.subscribe(
      e=>{
        if(!this.appHelper.isNullOrEmpty(e)){
          if(e == "Clear"){
            this.errors = [];
          } else {
            this.errors.push({message: "Error: "+ e, time: Date.now()});
          }
        }
      }
    );

    setInterval(()=>{
      let errorsToRemove = this.errors.filter(er=>Date.now().valueOf() - er.time.valueOf() > 3000);
      errorsToRemove.forEach(e=>this.errors.splice(this.errors.indexOf(e), 1));
    }, 1000);
  }

  loading: boolean = true;
  errors: any[] = [];
  ngOnInit() {
    
  }

  onErrorClose(err: any){
    this.errors.splice(this.errors.indexOf(err), 1);
    return false;
  }

}
