export namespace AvalonLookupModels {
    export class ABELookupTable {
        Id: string;
        ParentID: string;
        TableName: string;
        Values: ABELookupValue[]
    }

    export class ABELookupValue {
        AllowedContexts: string;
        ExtraData:  string;
        Id:  string;
        LookupName:  string;
        ParentTableID:  string;
        ParentValueID:  string;
        Sequence: number
    }
}