import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { GenesisProviderService } from './genesis-service-provider.service';
import { AppHelperService } from './app-helper.service';
import { concatMap, map } from 'rxjs/operators';

@Injectable()
export class LookupsResolver implements Resolve<Observable<any>> {
  constructor(private genesis: GenesisProviderService, private appHelper: AppHelperService) { 
      this.appHelper.genesisService = this.genesis;
  }

  resolve() {
      return this.appHelper.loadLookups().pipe(
          concatMap(p=>this.appHelper.getLoggedInIndividual())
      );
  }
}