import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AvalonModels } from 'src/app/models/avalon.models';
import { AppHelperService } from 'src/app/services/app-helper.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username: string = "";
  password: string = "";
  constructor(private appHelper: AppHelperService, private router: Router) { }

  ngOnInit(): void {
  }

  loginForm = new FormGroup( {
    userName: new FormControl('', [
      Validators.required
    ]),
    password: new FormControl('', [
      Validators.required
    ])
  });
  
  login(event: Event){
    this.appHelper.login(this.username, this.password)
                  .pipe(map(x=>{
                    if(x) {
                      this.router.navigate(["/dashboard"]);
                    }
                  }))
                  .subscribe();
    return false;
  }
}
