import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { combineAll, first, map, mergeAll, mergeMap } from 'rxjs/operators';
import { AvalonModels } from 'src/app/models/avalon.models';
import { SalModels } from 'src/app/models/sal.models';
import { AppHelperService } from 'src/app/services/app-helper.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {

  Survey: SalModels.SalSurvey;
  progress: number = 0;
  started: boolean = false;
  lastQuestion: boolean = false;
  submitted: boolean = false;
  currentQuestion: SalModels.SalSurveyQuestion;
  canGoPrevious: boolean = false;
  ipAddress: string = "0.0.0.0";
  showNav: boolean = false;
  questionTypes = SalModels.QuestionType;
  readOnly: boolean = false;

  constructor(private appHelper: AppHelperService, 
              private router: Router,
              private route: ActivatedRoute,
              private loaderService: LoaderService) { 
              }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.loadSurvey(params.get("id"));
    })
  }

  loadSurvey(questionId: string) {
    let survey = new SalModels.SalSurvey();
    survey.DisplayId = Number.parseInt(questionId);

    this.readOnly = this.appHelper.isLookupMatch(this.appHelper.surveyRun.Status, "Completed");

    //load survey..
    this.appHelper.genesisService.readAbe(survey)
                  .pipe(
                    mergeMap(
                      s=>{
                        this.Survey = s;
                        this.appHelper.setHeaderTitle(s.name);
                        return this.appHelper.genesisService.listByOwner(this.Survey, new SalModels.SalSurveyQuestion());
                      }
                    ),
                    mergeMap(
                      questions=>{
                        let obs:Observable<SalModels.SalQuestionResponse[]>[] = [];
                        this.Survey.questions = questions.sort((x,y)=>{return x.position > y.position ? 1 : -1});
                        this.Survey.questions.forEach(q=>{
                          let responseType = this.appHelper.getLookupName(q.response_type, "surveyquestion.response_type");
                          switch (responseType) {
                            case "Multiple Choice":
                              q.questionType = this.questionTypes.MultipleChoice;
                              break;
                            case "Single Choice":
                              q.questionType = this.questionTypes.SingleChoice;
                              break;
                            case "Free Text":
                              q.questionType = this.questionTypes.FreeText;
                              break;
                          
                            default:
                              q.questionType = this.questionTypes.Information;
                              break;
                          }
                          let ob = this.appHelper.genesisService.listByOwner(q, new SalModels.SalQuestionResponse())
                                                .pipe(
                                                  map(
                                                    responses=>{
                                                      q.responses = responses.sort((x,y)=>{return x.position > y.position ? 1 : -1});
                                                      return responses;
                                                    }
                                                  )
                                                );
                          obs.push(ob);
                        });
                        return forkJoin(obs);
                      }
                    )
                  ).subscribe({
                    complete: ()=>{
                      this.appHelper.survey = this.Survey;
                      this.loadExistingResponses();
                    }
                  });

    

    
    this.appHelper.getIpAddress()
                  .pipe(
                    map(ip=>{
                      this.ipAddress = ip;
                    })
                  ).subscribe();
  }

  loadExistingResponses() {
    //load existing responses
    this.appHelper.genesisService.listByOwner(this.appHelper.surveyRun, new SalModels.SalResponseHeader())
                                  .pipe(
                                    map(rh=>{
                                      if(rh.length > 0) {
                                        return rh[0];
                                      } else {
                                        return null;
                                      }
                                    }),
                                    mergeMap(rh => {
                                      this.appHelper.responseHeader = rh;
                                      return this.appHelper.genesisService.listByOwner(rh, new AvalonModels.Responses());
                                    }),
                                    map(
                                      responses=>{
                                        this.appHelper.responseHeader.responses = responses;
                                        let unasweredQuestionSet: boolean = false;
                                        //if survey has been started and there are responses, go to the next unanswered question
                                        for (let idx = 0; idx < this.Survey.questions.length; idx++) {
                                          const tmpQuestion = this.Survey.questions[idx];
                                          if(tmpQuestion.questionType == SalModels.QuestionType.Information) {
                                            continue;
                                          }
                                          const response = responses.find(r=>r.question_id.toLowerCase() == tmpQuestion.EntityId.toLowerCase());
                                          if(response == null || (response.response_value == null && response.response_id == null)) {
                                            tmpQuestion.isComplete = false;
                                            if(!unasweredQuestionSet) {
                                              unasweredQuestionSet = true;
                                              this.setQuestion(tmpQuestion);
                                            }
                                          } else {
                                            tmpQuestion.isComplete = true;
                                          }
                                        }
                                        if(responses.length > 0 && !unasweredQuestionSet){
                                          if(this.appHelper.responseHeader.end_time == null) {
                                          //move to the last question as this would only occur if all qs have been answered..
                                            this.setQuestion(this.Survey.questions[this.Survey.questions.length - 1]);
                                          } else {
                                            this.setQuestion(this.Survey.questions[0]);
                                          }
                                        }
                                      }
                                    )
                                  ).subscribe();
  }

  startSurvey() {
    this.nextQuestion();
    this.appHelper.surveyRun.Status = this.appHelper.getLookupId("In Progress", "surveyRun.status");
    this.appHelper.surveyRun.SurveyEntity = null;
    this.appHelper.genesisService.saveAbe(this.appHelper.surveyRun)
                                 .subscribe();
  }

  nextQuestion(){
    this.started = true;
    this.showNav = true;
    let nextQ: SalModels.SalSurveyQuestion;
    if(this.currentQuestion == null) {
      nextQ = this.Survey.questions[0];
    } else {
      nextQ = this.Survey.questions[this.Survey.questions.indexOf(this.currentQuestion) + 1];
    }
    this.setQuestion(nextQ);
  }

  setHeaderNavigate(){
    this.updateProgress();
    this.appHelper.setQuestion(this.currentQuestion);
    this.router.navigate([{outlets: {detail: ["survey-question", this.currentQuestion.DisplayId]}}], { relativeTo: this.route});
  }

  selectQuestion($event: any){
    // this.setQuestion(value);
    this.setQuestion(this.currentQuestion);
  }

  setQuestion(question: SalModels.SalSurveyQuestion) {
    this.loaderService.clearErrors();
    this.started = true;
    this.showNav = true;
    this.currentQuestion = question;
    let qIdx = this.Survey.questions.indexOf(this.currentQuestion);
    if(qIdx == this.Survey.questions.length -1) {
      this.lastQuestion = true;
    } else {
      this.lastQuestion = false;
    }
    if(qIdx == 0) {
      this.canGoPrevious = false;
    } else {
      this.canGoPrevious = true;
    }
    this.setHeaderNavigate();
  }

  updateProgress(){
    let currentIdx = this.Survey.questions.indexOf(this.currentQuestion);
    this.progress = (currentIdx / this.Survey.questions.length) * 100;
  }

  saveNext(){
    this.loaderService.clearErrors();
    this.saveResponse();
  }

  saveResponse() {
    if(!this.readOnly && this.currentQuestion.questionType != SalModels.QuestionType.Information) {
      let response = new AvalonModels.Responses();
      this.setSelectedResponse(response);
      if(!this.isResponseValid(response) 
          && this.currentQuestion.IsOptional != true) {
        this.loaderService.errors.next("Please select a response before proceeding.");
        return false;
      }

      this.saveResponseToDB()
          .subscribe({
                  complete: ()=>{
                    this.nextQuestion();
                  }
                });
    } else {
      this.nextQuestion();
    }
  }

  saveResponseToDB(){
    //get existing response
    let rh = this.appHelper.responseHeader;
    if(rh == null){
      rh = new SalModels.SalResponseHeader();
      rh.OwnerEntityTypeId = this.appHelper.surveyRun.EntityTypeId;
      rh.OwnerEntityId = this.appHelper.surveyRun.EntityId;
      rh.start_time = new Date();
    }
    rh.ip_address = this.ipAddress;
    return this.appHelper.genesisService.saveAbe(rh)
                                .pipe(
                                  map(srh=>{
                                    srh.responses = rh.responses;
                                    this.appHelper.responseHeader = srh;
                                    return srh;
                                  }),
                                  mergeMap(srh=>{
                                    let resp = srh.responses.find(r=>r.question_id.toLowerCase() == this.currentQuestion.EntityId.toLowerCase());
                                    if(resp == null){
                                      resp = new AvalonModels.Responses();
                                      resp.OwnerEntityId = srh.EntityId;
                                      resp.OwnerEntityTypeId = srh.EntityTypeId;
                                      resp.question_id = this.currentQuestion.EntityId;
                                    }
                                    this.setSelectedResponse(resp);
                                    this.currentQuestion.isComplete = this.isResponseValid(resp);
                                    return this.appHelper.genesisService.saveAbe(resp);
                                  }),
                                  map(
                                    savedResp=>{
                                      let existingResponse = this.appHelper.responseHeader.responses.find(r=>r.question_id.toLowerCase() == this.currentQuestion.EntityId.toLowerCase());
                                      if(existingResponse != null){
                                        let existingIdx = this.appHelper.responseHeader.responses.indexOf(existingResponse);
                                        this.appHelper.responseHeader.responses[existingIdx] = savedResp;
                                      } else {
                                        this.appHelper.responseHeader.responses.push(savedResp);
                                      }
                                    }
                                  )
                                );
  }

  previous() {
    this.lastQuestion = false;
    this.currentQuestion = this.Survey.questions[this.Survey.questions.indexOf(this.currentQuestion) - 1];
    if(this.Survey.questions.indexOf(this.currentQuestion) == 0) {
      this.canGoPrevious = false;
    }
    this.setHeaderNavigate();
  }

  isResponseValid(resp: AvalonModels.Responses) : boolean {
    if(!this.appHelper.isNullOrEmpty(resp.response_value) || !this.appHelper.isNullOrEmpty(resp.response_id)) {
      return true;
    }
    return false;
  }

  setSelectedResponse(response: AvalonModels.Responses){
    switch (this.currentQuestion.questionType) {
      case SalModels.QuestionType.MultipleChoice:
        response.response_id = this.currentQuestion.responses.filter(r=>r.isSelected).map(r=>r.EntityId).join(',');
        break;
      case SalModels.QuestionType.SingleChoice:
        response.response_id = this.currentQuestion.selectedResponseValue;
        break;
      case SalModels.QuestionType.FreeText:
        response.response_value = this.currentQuestion.selectedResponseValue;
        break;
      default:
        break;
    }
  }

  submit() {
    if(confirm("Are you sure? Once submitted you will not be able to edit your submission.")) {
      //submit and save..
      this.appHelper.responseHeader.end_time = new Date();
      this.saveResponseToDB()
          .pipe(
            mergeMap(x=>{
              this.appHelper.surveyRun.Status = this.appHelper.getLookupId("Completed", "surveyRun.status");
              this.appHelper.surveyRun.SurveyEntity = null;
              return this.appHelper.genesisService.saveAbe(this.appHelper.surveyRun);
            }),
            map(sr=>{
              return sr;
            })
          )
          .subscribe({
                  complete: ()=>{
                    this.submitted = true;
                    this.appHelper.setHeaderTitle("Submitted successfully!");
                  }
                });
    }
  }
}
