import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
 
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public isLoading = new BehaviorSubject(false);
  public errors = new BehaviorSubject("");
  constructor() { }

  public clearErrors(){
    this.errors.next("Clear");
  }
}