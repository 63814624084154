import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SurveyComponent } from './components/survey/survey.component';
import { SurveyQuestionComponent } from './components/survey-question/survey-question.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMaterialModule } from './modules/app-material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClient, HttpClientModule, HttpHandler, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LookupsResolver } from './services/lookups-resolver';
import { AccessControlActivate } from './services/access-control-activate';
import { LoaderService } from './services/loader.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LoaderInterceptor } from './services/loader.interceptor';
import { LookupNamePipe } from './pipes/lookup.pipe';
import { LoaderComponent } from './components/loader/loader.component';

@NgModule({
  declarations: [
    AppComponent,
    LookupNamePipe,
    SurveyComponent,
    SurveyQuestionComponent,
    LoginComponent,
    DashboardComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppMaterialModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    { 
      provide: 'endpoint', 
      useValue: (<any> window).AvalonEndpoint,
    },
    LookupsResolver,
    AccessControlActivate,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {provide: MAT_DATE_LOCALE, useValue: 'en-AU'}
  ],
  
  bootstrap: [AppComponent]
})
export class AppModule { }
